import * as React from 'react';
import cn from 'classnames';
import OrderHistoryItem from './OrderHistory/OrderHistoryItem';
import Grid from 'styleguide/components/Grid/Grid';
import Search from 'bundles/App/pages/Account/Search/Search';
import { Status } from 'libs/utils/api/types';
import Footer from 'styleguide/components/Footer/Footer';
import TileHeader from 'styleguide/components/TileHeader/TileHeader';
import { getOrders, reorder } from 'api/account/orders';
import { OrdersResponse } from 'api/types';
import Meta from 'styleguide/components/Meta/Meta';
import Loader from 'styleguide/components/Loader/Loader';
import { OrderBasic } from 'api/orders/types';
import OrderContext from 'contexts/OrderContext/OrderContext';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { cartPath } from 'bundles/App/routes';
import { setOrder } from 'contexts/OrderContext/actions';
import ReorderModal from 'styleguide/ReorderModal/ReorderModal';
import EmptyPage from './EmptyPage/EmptyPage';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Fragment } from 'react';
import Breadcrumb from 'styleguide/components/Breadcrumb/Breadcrumb';

const TABS = ['In Progress', 'Completed', 'All Orders', 'Canceled', 'Quotes'];
const orderStateTypes = {
  Quotes: ['Quote'],
  Completed: ['Order Shipped', 'Ready for Pickup', 'Returned/Refunded'],
  'In Progress': [
    'Digital Proof Sent',
    'Digital Proof Coming Soon',
    'Design Coming Soon',
    'In Production',
    'Shipping',
    'Waiting on Digital Proof Approval',
    'Waiting on Hard Copy Proof Approval',
    'Processing Refund/Cancel',
    'Waiting on Customer Files',
    'Waiting For Payment',
  ],
  Canceled: ['Canceled'],
};

const OrderItemsHeader = () => (
  <div className="flex px-6 -lg:hidden">
    <div className="grid w-full font-hvBold text-xs text-gray-500 md:grid-cols-5 -md:grid-cols-2">
      <div className="justify-self-start pl-2 lg:pl-8">Order Number</div>
      <div className="justify-self-start">Status</div>
      <div className="justify-self-start -md:hidden">Order Placed</div>
      <div className="justify-self-start -md:hidden">Total</div>
      <div className="justify-self-center -md:hidden">Actions</div>
    </div>
  </div>
);

const OrderHistoryPage = () => {
  const history = useHistory();
  const [orders, setOrders] = React.useState<OrderBasic[]>(null);
  const [ordersByState, setOrdersByState] = React.useState<OrderBasic[]>(null);
  const [searchField, setSearchField] = React.useState<string>(null);
  const [currentTab, setCurrentTab] = React.useState<string>('In Progress');
  const [debouncedSearchField] = useDebounce(searchField, 1000);
  const [reorderInProgress, setReorderInProgress] = React.useState<boolean>(null);
  const { dispatch } = React.useContext(OrderContext);

  const filterByTab = (allOrders, tab) =>
    allOrders.filter(order => {
      if (tab === 'All Orders') {
        return true;
      }

      return orderStateTypes[tab].includes(order.customerState);
    });

  const refreshOrderList = () => {
    const handleSuccess = (response: OrdersResponse) => {
      setOrders(response.orders);
      setOrdersByState(filterByTab(response.orders, currentTab));
    };

    getOrders(searchField).then(res => {
      if (res.status === Status.Ok) {
        handleSuccess(res.payload);
      }
    });
  };

  React.useEffect(() => {
    refreshOrderList();
  }, [debouncedSearchField]);

  const handleReorder = orderNumber => {
    setReorderInProgress(true);
    reorder(orderNumber).then(res => {
      if (res.status === Status.Ok) {
        setOrder(dispatch, res.payload);
        history.push(cartPath);
      } else {
        setReorderInProgress(false);
      }
    });
  };

  return (
    <>
      {reorderInProgress && <ReorderModal action="reorder" />}
      <Meta
        title="Account"
        description=""
        keywords=""
        robots={['noindex']}
        canonical="https://www.printivity.com/account"
      />
      <TileHeader
        title="Orders"
        subText="Manage your recent orders."
        Breadcrumb={
          <Breadcrumb
            pages={[{ label: 'Home', link: '/' }, { label: 'Orders' }, { label: 'Order History' }]}
          />
        }
      />
      <div className="mx-auto mt-4 block max-w-base px-2">
        {orders ? (
          <TabGroup as={Fragment}>
            <div className="flex items-center justify-between md:space-x-4 border-t border-neutral-200 pt-4 -md:flex-col -md:items-start">
              <TabList className="isolate flex h-9 flex-row flex-nowrap space-x-2 overflow-x-scroll scrollbar-hide md:space-x-4">
                {TABS.map((tab, index) => (
                  <Tab as={Fragment} key={index}>
                    {({ selected }) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      <div
                        className={cn(
                          selected
                            ? 'rounded-t-md border-b-2 border-blue bg-shades-0 text-blue'
                            : 'text-gray-600 hover:rounded-t-md hover:border-b-2 hover:border-gray-400 hover:bg-gray-50 hover:text-default',
                          'relative flex flex-none cursor-pointer select-none flex-col justify-end overflow-y-visible px-1 pb-1 text-sm',
                        )}
                        onClick={() => {
                          setOrdersByState(filterByTab(orders, tab));
                          setCurrentTab(tab);
                        }}
                        role="tab"
                        tabIndex={0}
                      >
                        {tab}
                      </div>
                    )}
                  </Tab>
                ))}
              </TabList>
              <div className="grow md:max-w-sm -md:mt-5 -md:w-full">
                <Search
                  placeholder="Search by Order #, Email or Project Name"
                  handleSearch={newSearchField => setSearchField(newSearchField)}
                />
              </div>
            </div>
            <TabPanels className="mt-12">
              {TABS.map(() => (
                <TabPanel>
                  {ordersByState?.length > 0 ? (
                    <>
                      <OrderItemsHeader />
                      {ordersByState.map(elem => (
                        <OrderHistoryItem key={elem.number} order={elem} handleReorder={handleReorder} />
                      ))}
                    </>
                  ) : (
                    <EmptyPage />
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Col className="mt-5 flex justify-center">
                <Loader size="xl" />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        )}
      </div>
      <Footer />
    </>
  );
};

export default OrderHistoryPage;
